import { Pipe, PipeTransform } from '@angular/core';
import { PipeCollector } from '@angular/compiler/src/template_parser/binding_parser';
import { ReservationService } from '../modules/bookings/reservation.service';

@Pipe({
  name: 'bookingCost'
})
export class BookingCostPipe implements PipeTransform {

  constructor(
    private reservationService: ReservationService,
  ) { }

  transform(value: any, arg1: any, arg2: any): any {
    console.log('valie ', value)
    console.log('arg1 ', arg1)
    console.log('arg2 ', arg2)
    let cost = this.findFare(value, arg1, arg2);
    console.log('return return ', cost)
    return cost;
  }

  findFare(pick, drop, pax) {
    return this.reservationService.getFare(pick, drop, pax)
      .subscribe(response => {
        const res: any = response;
        if (res.success) {
          console.log('return ', res.body.cost)
          return res.body.cost;
        }
        console.log('return null')
        return null;
      });

  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from '../../app-routing.module';
import { CallToActionComponent } from './call-to-action/call-to-action.component';



@NgModule({
  declarations: [HeaderComponent, FooterComponent, CallToActionComponent],
  exports:[HeaderComponent, FooterComponent,CallToActionComponent],
  imports: [
    CommonModule,
    AppRoutingModule
  ]
})
export class CommonComponentsModule { }

import { Component, OnInit, OnDestroy } from '@angular/core';
import { CalenderService } from '../calender.service';
import { appConfig } from '../../../../app/app.config';

@Component({
  selector: 'app-calender-main',
  templateUrl: './calender-main.component.html',
  styleUrls: ['./calender-main.component.css']
})
export class CalenderMainComponent implements OnInit, OnDestroy {
  appConfig = appConfig;

  constructor(public calenderService: CalenderService) {
    appConfig.bIsCalender = true;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.calenderService.bUserLoggedIn = false;
    appConfig.bIsCalender = false;
  }
}

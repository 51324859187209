import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalenderMainComponent } from './calender-main/calender-main.component';
import { CalenderLoginComponent } from './calender-login/calender-login.component';
import { CalenderViewComponent } from './calender-view/calender-view.component';
import { CalenderService } from './calender.service';
import { RouterModule, Routes } from '@angular/router';

export const calenderRoutes: Routes = [
  {
    path: '',
    component: CalenderMainComponent,
    // children: [
    //   // { path: 'receipt/:id', component: PaymentMainComponent },
    //   // { path: 'invoice/:id', component: PaymentMainComponent }
    // ]
  },
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(calenderRoutes),
  ],
  declarations: [CalenderMainComponent, CalenderLoginComponent, CalenderViewComponent],
  providers: [CalenderService]
})
export class CalenderModule { }

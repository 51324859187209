import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calender-login',
  templateUrl: './calender-login.component.html',
  styleUrls: ['./calender-login.component.css']
})
export class CalenderLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  loginUser(){}

}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'peakCharge'
})
export class PeakChargePipe implements PipeTransform {

  transform(value: any, argTime?: any): any {
    const format = 'HH:mm'
    let peakCost = null;
    console.log('argTime ', argTime)
    const time = argTime ? moment(argTime, format) : moment(moment().format(format), format);
    console.log('Time ', moment(moment(argTime).format(format), format));
    const beforeTime = moment('22:00', format);
    const afterTime = moment('06:30', format);
    if (time.isSameOrBefore(beforeTime) && time.isSameOrAfter(afterTime)) {
      peakCost = 0;
    } else {
      peakCost = 20;
    }
    return peakCost;
  }

}

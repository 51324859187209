import { Component, OnInit, ViewEncapsulation, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { ReservationService } from '../reservation.service';
import { Destins } from '../../../shared/models/models';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown';
import { DestinationGroups, JourneyType } from '../../../shared/enums/common-enums';
import { CommonfuncService } from '../../../shared/services/commonfunc.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { PeakTimePipe } from 'src/app/pipes/peak-time.pipe';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-book-now',
  templateUrl: './book-now.component.html',
  styleUrls: ['./book-now.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BookNowComponent implements OnInit, AfterViewInit {

  @ViewChild('j1pkup', { static: false }) j1pickupDrop: AngularMultiSelect;
  @ViewChild('j1drop', { static: false }) j1dropDrop: AngularMultiSelect;
  @ViewChild('j2pkup', { static: false }) j2pickupDrop: AngularMultiSelect;
  @ViewChild('j2drop', { static: false }) j2dropDrop: AngularMultiSelect;

  filterPeakCost = new PeakTimePipe();

  pickupLocationList: Destins[] = [];
  pickupLocation: string;
  pickupDropdownSettings = {};

  dropLocationList: Destins[] = [];
  dropLocation: string;
  dropDropdownSettings = {};

  paxCountList = [];
  paxCount: number;
  paxCountSettings = {};

  bJourney2: boolean;
  bShowFT: boolean;
  bShowPickAdd: boolean;
  bShowDropAdd: boolean;
  bj2ShowFT: boolean;
  bj2ShowPickAdd: boolean;
  bj2ShowDropAdd: boolean;
  loading: boolean;

  public j1minDate: Date;
  public j2minDate: Date;

  public bNext: boolean;
  public bError: boolean;
  public bInvalidEml: boolean;

  public sError = '*This field is required'
  tripData: {};
  customerObj: {};
  created: boolean;
  fair1: any;
  fair2: any;

  constructor(
    public reservationService: ReservationService,
    private commonfuncService: CommonfuncService,
    // tslint:disable-next-line: variable-name
    private _router: Router,
  ) {
    this.initializeConstructor();
  }

  ngOnInit() {
    this.initializeOnInit();
  }

  initializeConstructor() {
    this.pickupLocation = '';
    this.dropLocation = '';
    this.paxCount = 0;

    this.bJourney2 = false;

    this.bShowFT = false;
    this.bShowPickAdd = false;
    this.bShowDropAdd = false;
    this.bj2ShowPickAdd = false;
    this.bj2ShowDropAdd = false;
    this.loading = false;

    this.j1minDate = new Date();
    this.j1minDate.setDate(this.j1minDate.getDate() - 1);
    this.j2minDate = new Date();
    this.j2minDate.setDate(this.j2minDate.getDate() - 1);

    this.bNext = false;
    this.bError = false;
    this.bInvalidEml = false;
    this.created = false;
  }

  initializeOnInit() {
    this.pickupLocationList = this.reservationService.pickupLocationList;
    this.dropLocationList = this.reservationService.dropLocationList;
    this.paxCountList = this.reservationService.paxCountList;

    this.pickupDropdownSettings = this.reservationService.pickupDropdownSettings;
    this.dropDropdownSettings = this.reservationService.dropDropdownSettings;
    this.paxCountSettings = this.reservationService.paxCountSettings;
  }

  ngAfterViewInit() {
    if (this.reservationService.dropLocation && this.reservationService.dropLocation.length > 0) {
      this.onDropSelect(this.reservationService.dropLocation[0]);
    } else {
      this.bShowDropAdd = false;
    }
    if (this.reservationService.pickupLocation && this.reservationService.pickupLocation.length > 0) {
      this.onPickupSelect(this.reservationService.pickupLocation[0]);
    } else {
      this.bShowFT = false;
    }
    if (this.reservationService.j2PickupLocation && this.reservationService.j2PickupLocation.length > 0) {
      this.onPickupSelectJ2(this.reservationService.j2PickupLocation[0]);
    } else {
      this.bj2ShowFT = false;
    }
    if (this.reservationService.j2DropLocation && this.reservationService.j2DropLocation.length > 0) {
      this.onDropSelectJ2(this.reservationService.j2DropLocation[0]);
    } else {
      this.bj2ShowDropAdd = false;
    }
  }

  onJtypeClick(event) {
    this.reservationService.sJourneyType = event.target.value;
    if (this.reservationService.sJourneyType === 'Roundtrip') {
      this.bJourney2 = true;
    } else {
      this.bJourney2 = false;
    }
  }

  onPaxSelect(item: any) {
    this.reservationService.onPaxSelect(item);
  }

  onPickupSelect(item: any) {
    this.reservationService.onPickupSelect(item, JourneyType.ONEWAY);
    this.j1pickupDrop.closeDropdown();
    if (this.reservationService.pickupLocation[0] && this.reservationService.pickupLocation[0].type != DestinationGroups.OTHER) {
      this.bShowFT = true;
      this.bShowPickAdd = false;
    } else if (this.reservationService.pickupLocation[0]) {
      this.bShowFT = false;
      this.bShowPickAdd = true;
    } else {
      this.bShowFT = true;
      this.bShowPickAdd = false;
    }

  }

  onDropSelect(item: any) {
    this.reservationService.onDropSelect(item, JourneyType.ONEWAY);
    this.j1dropDrop.closeDropdown();
    if (this.reservationService.dropLocation[0] && this.reservationService.dropLocation[0].type == DestinationGroups.OTHER) {
      this.bShowDropAdd = true;
    } else {
      this.bShowDropAdd = false;
    }
  }

  onPickupSelectJ2(item: any) {
    this.reservationService.onPickupSelect(item, JourneyType.ROUNDTRIP);
    this.j2pickupDrop.closeDropdown();
    if (this.reservationService.j2PickupLocation[0] && this.reservationService.j2PickupLocation[0].type != DestinationGroups.OTHER) {
      this.bj2ShowFT = true;
      this.bj2ShowPickAdd = false;
    } else if (this.reservationService.j2PickupLocation[0]) {
      this.bj2ShowFT = false;
      this.bj2ShowPickAdd = true;
    } else {
      this.bj2ShowFT = false;
      this.bj2ShowPickAdd = false;
    }
  }

  onDropSelectJ2(item: any) {
    this.reservationService.onDropSelect(item, JourneyType.ROUNDTRIP);
    this.j2dropDrop.closeDropdown();

    if (this.reservationService.j2DropLocation[0] && this.reservationService.j2DropLocation[0].type == DestinationGroups.OTHER) {
      this.bj2ShowDropAdd = true;
    } else {
      this.bj2ShowDropAdd = false;

    }
  }

  onPickDateJ1() {
    console.log('date1', this.reservationService.j1PickDate);
    const date = new Date(this.reservationService.j1PickDate);
  }

  onPickDateJ2() {
    const date = new Date(this.reservationService.j2PickDate);
  }

  onPickTimeJ1() {
    const date = new Date(this.reservationService.j1PickTime);
  }
  onPickTimeJ2() {
    const date = new Date(this.reservationService.j2PickTime);
  }

  nextStep() {
    this.validateNext();
    if (!this.bError) {
      document.getElementById('bookingheader').scrollIntoView();
      this.bNext = true;
      this.onCalcFair(
        this.reservationService.pickupLocation[0].id,
        this.reservationService.dropLocation[0].id,
        this.reservationService.paxCount[0].itemName,
        1);
      if (this.reservationService.sJourneyType === 'Roundtrip') {
        this.onCalcFair(
          this.reservationService.j2PickupLocation[0].id,
          this.reservationService.j2DropLocation[0].id,
          this.reservationService.paxCount[0].itemName,
          2);
      }
    } else {
      this.bNext = false;
    }
  }

  validateNext() {
    this.bError = false;
    if (
      (this.reservationService.paxCount.length <= 0) || (this.reservationService.pickupLocation.length <= 0) ||
      (this.bShowFT && !this.reservationService.j1FTnumber) ||
      (this.bShowFT && !this.reservationService.j1FTorigin) ||
      (this.bShowPickAdd && !this.reservationService.j1PickupAddress) ||
      (!this.reservationService.j1PickDate) ||
      (!this.reservationService.j1PickTime) ||
      (this.reservationService.dropLocation.length <= 0) ||
      (this.bShowDropAdd && !this.reservationService.j1DropAddress)
    ) {
      this.bError = true;
    }
    if (this.bJourney2) {
      if (
        (this.reservationService.j2PickupLocation.length <= 0) ||
        (this.bj2ShowFT && !this.reservationService.j2FTnumber) ||
        (this.bj2ShowFT && !this.reservationService.j2FTorigin) ||
        (this.bj2ShowPickAdd && !this.reservationService.j2PickupAddress) ||
        (!this.reservationService.j2PickDate) ||
        (!this.reservationService.j2PickTime) ||
        (this.reservationService.j2DropLocation.length <= 0) ||
        (this.bj2ShowDropAdd && !this.reservationService.j2DropAddress)
      ) {
        this.bError = true;
      }
    }
  }

  submit() {
    this.loading = true;
    if (this.reservationService.customerName && this.checkEmail() && this.reservationService.customerPhone) {
      document.getElementById('bookingheader').scrollIntoView();
      this.bError = false;
      let jType;
      if (this.reservationService.sJourneyType === 'Oneway') {
        jType = 1;
      }
      if (this.reservationService.sJourneyType === 'Roundtrip') {
        jType = 2;
      }
      this.customerObj = {
        s_name: this.reservationService.customerName,
        s_email: this.reservationService.customerEmail,
        s_telephone: this.reservationService.customerPhone,
      };
      this.tripData = {
        i_journey_type: jType,
        i_passenger_count: this.reservationService.paxCount[0].itemName,
        s_pickup_location_1: this.reservationService.pickupLocation[0].itemName,
        s_pickup_address_1: this.reservationService.j1PickupAddress ? this.reservationService.j1PickupAddress : '',
        s_pickup_vehicle_number_1: this.reservationService.j1FTnumber ? this.reservationService.j1FTnumber : '',
        s_pickup_vehicle_orgin_1: this.reservationService.j1FTorigin,
        s_pickup_date_1: moment(this.reservationService.j1PickDate).format('DD/MM/YYYY'),
        s_pickup_time_1: moment(this.reservationService.j1PickTime).format('HH:mm'),
        s_dropoff_location_1: this.reservationService.dropLocation[0].itemName,
        s_dropoff_address_1: this.reservationService.j1DropAddress ? this.reservationService.j1DropAddress : '',
        d_journey_cost_1: this.filterPeakCost.transform(this.fair1, this.reservationService.j1PickTime),
        s_pickup_location_2: this.reservationService.sJourneyType === 'Roundtrip' ?
          this.reservationService.j2PickupLocation[0].itemName : '',
        s_pickup_address_2: this.reservationService.sJourneyType === 'Roundtrip' ?
          (this.reservationService.j2PickupAddress ? this.reservationService.j2PickupAddress : '') : '',
        s_pickup_vehicle_number_2: this.reservationService.sJourneyType === 'Roundtrip' ?
          (this.reservationService.j1FTnumber ? this.reservationService.j2FTnumber : '') : '',
        s_pickup_vehicle_orgin_2: this.reservationService.sJourneyType === 'Roundtrip' ?
          (this.reservationService.j2FTorigin ? this.reservationService.j2FTorigin : '') : '',
        s_pickup_date_2: this.reservationService.sJourneyType === 'Roundtrip' ?
          moment(this.reservationService.j2PickDate).format('DD/MM/YYYY') : '',
        s_pickup_time_2: this.reservationService.sJourneyType === 'Roundtrip' ?
          moment(this.reservationService.j2PickTime).format('HH:mm') : '',
        s_dropoff_location_2: this.reservationService.sJourneyType === 'Roundtrip' ?
          this.reservationService.j2DropLocation[0].itemName : '',
        s_dropoff_address_2: this.reservationService.sJourneyType === 'Roundtrip' ?
          (this.reservationService.j2DropAddress ? this.reservationService.j2DropAddress : '') : '',
        d_journey_cost_2: this.filterPeakCost.transform(this.fair2, this.reservationService.j2PickTime),
        b_night_charges_added_1: this.filterPeakCost.transformPeakStatus(this.reservationService.j1PickTime),
        b_night_charges_added_2: this.filterPeakCost.transformPeakStatus(this.reservationService.j2PickTime),
        s_notes: this.reservationService.customerNotes,
        customer: this.customerObj
      };
      const data = {
        action: 'CreateBooking',
        body: {
          booking: this.tripData
        }
      };
      this.reservationService.onBookingTrip(data)
        .subscribe(res => {
          this.loading = false;
          const response: any = res;
          if (response.success === true) {
            this.created = true;

          }
        });
    } else {
      this.bError = true;
      this.loading = false;
    }
  }

  checkEmail() {
    this.bInvalidEml = false;
    if (this.reservationService.customerEmail) {
      if (this.commonfuncService.validateEmail(this.reservationService.customerEmail)) {
        return true;
      } else {
        this.bInvalidEml = true;
        return false;
      }
    } else {
      return false;
    }
  }

  bookNow() {
    this._router.navigate(['/reservations']);
  }

  onCalcFair(p: number, d: number, count: number, type: number) {
    this.fair1 = null;
    this.fair2 = null;
    this.reservationService.getFare(p, d, count)
      .subscribe(response => {
        const res: any = response;
        if (res.success) {
          switch (type) {
            case 1:
              this.fair1 = res.body.cost;
              break;
            case 2:
              this.fair2 = res.body.cost;
              break;
          }
        }
      });
  }

  onRefresh() {

    this.reservationService.resetAll();
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/reservations']);
  }
  onBack() {
    this.bNext = false;
    this.fair1 = null;
    this.fair2 = null;
  }
  get totalCost() {
    return Number(this.fair1) + Number(this.fair2);
  }
}

import { Component, OnInit } from '@angular/core';
import { ReservationService } from '../bookings/reservation.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  contact:any = {};
  b_error = false;
  b_loading = false;
  b_show_msg = false;

  constructor(private reservationService : ReservationService) { }

  ngOnInit() {
  }

  sendmsg(){
    this.b_error = false;
    this.b_loading = true;
    if(this.contact.s_name && this.contact.s_name!= '' &&
       this.contact.s_email && this.contact.s_email!= '' &&
       this.contact.s_subject && this.contact.s_subject!= '' &&
       this.contact.s_message && this.contact.s_message!= '')
       this.reservationService.sendMsg(this.contact).subscribe(response =>{
        this.b_loading = false;
         console.log(response)
         this.contact = {};
         this.b_show_msg = true;
         this.b_error = false;
       })
    else {
      this.b_loading = false;
      this.b_error = true;
    }
  }

}

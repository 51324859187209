import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateCustom'
})
export class DatePipe implements PipeTransform {

  transform(value: any): any {
    return moment(value).format('MMMM Do YYYY');
    // return moment(value).format('dddd, MMMM Do YYYY');
  }

}

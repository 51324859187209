import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  mobile = false;
  constructor() { }

  ngOnInit() {
    console.log('window.screen.width ', window.screen.width)
    if (window.screen.width <= 414) { // 768px portrait
      this.mobile = true;
    }
  }

}

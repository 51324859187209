import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonComponentsModule } from './shared/common-components/common-components.module';
import { MainPageComponent } from './modules/main-page/main-page.component';
import { BookNowComponent } from './modules/bookings/book-now/book-now.component';
import { ContactUsComponent } from './modules/contact-us/contact-us.component';
import { AboutUsComponent } from './modules/about-us/about-us.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
import { ReservationService } from './modules/bookings/reservation.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonfuncService } from './shared/services/commonfunc.service';
import { SendRequestsService } from './shared/services/send-requests.service';
import { CalenderModule } from './modules/calender/calender.module';
import { DatePipe } from './modules/bookings/date.pipe';
import { TimePipe } from './modules/bookings/time.pipe';
import { PeakTimePipe } from './pipes/peak-time.pipe';
import { BookingCostPipe } from './pipes/booking-cost.pipe';
import { PeakChargePipe } from './pipes/peak-charge.pipe';
import { TotalCostPipe } from './total-cost.pipe';
import { MdProgressCircleModule } from '@angular2-material/progress-circle';

export const MY_NATIVE_FORMATS = {
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
};

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    BookNowComponent,
    ContactUsComponent,
    AboutUsComponent,
    DatePipe,
    TimePipe,
    PeakTimePipe,
    BookingCostPipe,
    PeakChargePipe,
    TotalCostPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CommonComponentsModule,
    AngularMultiSelectModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CalenderModule,
    MdProgressCircleModule
  ],
  providers: [
    ReservationService,
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
    CommonfuncService,
    SendRequestsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

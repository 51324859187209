import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { MainPageComponent } from './modules/main-page/main-page.component';
import { ContactUsComponent } from './modules/contact-us/contact-us.component';
import { AboutUsComponent } from './modules/about-us/about-us.component';
import { BookNowComponent } from './modules/bookings/book-now/book-now.component';


const routes: Routes = [
  { path: 'reservations', component: BookNowComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'calender', loadChildren: './modules/calender/calender.module#CalenderModule' },
  { path: 'contact', component: ContactUsComponent },
  { path: '', component: MainPageComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: MainPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

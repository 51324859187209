import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalenderService {
  bUserLoggedIn: boolean;

  constructor() { 
    this.bUserLoggedIn = false;
  }
}

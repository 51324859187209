import { Injectable } from '@angular/core';
import { Destins } from '../../shared/models/models';
import { DestinationGroups, DestinationType, JourneyType } from '../../shared/enums/common-enums';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {
  _baseUrl = 'https://parisdisneyshuttle.com/API/Postback.php?sID=';
  public sJourneyType: string;

  public Destinations: Destins[] = [];


  public pickupLocationList: Destins[] = [];
  public pickupLocation = [];
  public pickupDropdownSettings = {};

  public dropLocationList: Destins[] = [];
  public dropLocation = [];
  public dropDropdownSettings = {};

  public j2PickupLocationList: Destins[] = [];
  public j2PickupLocation = [];
  // public j2PickupDropdownSettings = {};

  public j2DropLocationList: Destins[] = [];
  public j2DropLocation = [];
  // public j2DropDropdownSettings = {};

  public paxCountList = [];
  public paxCount = [];
  public paxCountSettings = {};

  public j1FTnumber: string;
  public j1FTorigin: string;
  public j1PickupAddress: string;
  public j1DropAddress: string;

  public j2FTnumber: string;
  public j2FTorigin: string;
  public j2PickupAddress: string;
  public j2DropAddress: string;

  public bLoading: boolean;

  public j1PickDate: Date;
  public j1PickTime: Date;

  public j2PickDate: Date;
  public j2PickTime: Date;

  public customerName: string;
  public customerEmail: string;
  public customerPhone: string;
  public customerNotes: string;


  constructor(
    private _http: HttpClient,
  ) {
    this.sJourneyType = 'Oneway';
    
    this.getLocations().subscribe((response : any)=>{
      for(let id in response.body.destinations){
        let item = response.body.destinations[id];
        this.Destinations.push({
          id : +id,
          itemName : item.s_name,
          type : item.s_type
        })
      }
      this.pickupLocationList = this.Destinations;
      this.dropLocationList = this.Destinations;
      this.j2PickupLocationList = this.Destinations;
      this.j2DropLocationList = this.Destinations;
      
    })

    this.pickupDropdownSettings = {
      text: 'Pickup Location',
      groupBy: 'type',
      enableCheckAll: false,
      showCheckbox: false,
      selectGroup: false
    };

    this.dropDropdownSettings = {
      text: 'Drop Location',
      groupBy: 'type',
      enableCheckAll: false,
      showCheckbox: false,
      selectGroup: false
    };

    for (let i = 1; i <= 30; i++) {
      const item = { id: i, itemName: i };
      this.paxCountList.push(item);
    }

    this.paxCountSettings = {
      singleSelection: true,
      text: 'No. of pax'
    };

    this.bLoading = false;

    this.customerName = '';
    this.customerEmail = '';
    this.customerPhone = '';
    this.customerNotes = '';
  }

  resetAll() {

    this.pickupLocationList = [];
    this.pickupLocation = [];
    this.pickupDropdownSettings = {};
    this.dropLocationList= [];
    this.dropLocation = [];
    this.dropDropdownSettings = {};
    this.j2PickupLocationList = [];
    this.j2PickupLocation = [];
    this.j2DropLocationList= [];
    this.j2DropLocation = [];
    this.paxCountList = [];
    this.paxCount = [];
    this.paxCountSettings = {};

    this.j1FTnumber='';
    this.j1FTorigin='';
    this.j1PickupAddress='';
    this.j1DropAddress='';
    this.j2FTnumber='';
    this.j2FTorigin='';
    this.j2PickupAddress='';
    this.j2DropAddress='';
    this.bLoading=false;
    this.j1PickDate=null;
    this.j1PickTime=null;
    this.j2PickDate=null;
    this.j2PickTime=null;
    this.customerName='';
    this.customerEmail='';
    this.customerPhone='';
    this.customerNotes='';


    this.sJourneyType = 'Oneway';

    this.pickupLocationList = this.Destinations;
    this.dropLocationList = this.Destinations;
    this.j2PickupLocationList = this.Destinations;
    this.j2DropLocationList = this.Destinations;

    this.pickupDropdownSettings = {
      text: 'Pickup Location',
      groupBy: 'type',
      enableCheckAll: false,
      showCheckbox: false,
      selectGroup: false
    };

    this.dropDropdownSettings = {
      text: 'Drop Location',
      groupBy: 'type',
      enableCheckAll: false,
      showCheckbox: false,
      selectGroup: false
    };

    for (let i = 1; i <= 30; i++) {
      const item = { id: i, itemName: i };
      this.paxCountList.push(item);
    }

    this.paxCountSettings = {
      singleSelection: true,
      text: 'No. of pax'
    };

    this.bLoading = false;

    this.customerName = '';
    this.customerEmail = '';
    this.customerPhone = '';
    this.customerNotes = '';
  }

  public onPickupSelect(item: any, jType: number) {
    switch (jType) {
      case JourneyType.ONEWAY:
        this.pickupLocation = [];
        if (item.length != 0) {
          this.pickupLocation.push(item.list.length > 0 ? item.list[0] : item);
          this.validateDropList(this.pickupLocation[0], DestinationType.PICKUP, jType);
        }
        break;
      case JourneyType.ROUNDTRIP:
        this.j2PickupLocation = [];
        if (item.length != 0) {
          this.j2PickupLocation.push(item.list.length > 0 ? item.list[0] : item);
          this.validateDropList(this.j2PickupLocation[0], DestinationType.PICKUP, jType);
        }
        break;
      default:
        break;
    }
  }
  public onDropSelect(item: any, jType: number) {
    switch (jType) {
      case JourneyType.ONEWAY:
        this.dropLocation = [];
        if (item.length != 0) {
          this.dropLocation.push(item.list.length > 0 ? item.list[0] : item);
          this.validateDropList(this.dropLocation[0], DestinationType.DROP, jType);
        }
        break;
      case JourneyType.ROUNDTRIP:
        this.j2DropLocation = [];
        if (item.length != 0) {
          this.j2DropLocation.push(item.list.length > 0 ? item.list[0] : item);
          this.validateDropList(this.j2DropLocation[0], DestinationType.DROP, jType);
        }
        break;
      default:
        break;
    }
  }
  public onPaxSelect(item: any) {
    this.paxCount = [];
    if (item.length != 0) {
      this.paxCount.push(item);
    }
  }

  private validateDropList(item: any, destype: number, jType: number) {
    let list = [];
    if (item.type != DestinationGroups.OTHER) {
      if (destype == DestinationType.PICKUP) {
        switch (jType) {
          case JourneyType.ONEWAY:
            this.dropLocationList = this.Destinations;
            list = this.dropLocationList.filter(i => i.id != item.id);
            this.dropLocationList = list;
            break;
          case JourneyType.ROUNDTRIP:
            this.j2DropLocationList = this.Destinations;
            list = this.j2DropLocationList.filter(i => i.id != item.id);
            this.j2DropLocationList = list;
            break;

          default:
            break;
        }

      } else {
        switch (jType) {
          case JourneyType.ONEWAY:
            this.pickupLocationList = this.Destinations;
            list = this.pickupLocationList.filter(i => i.id != item.id);
            this.pickupLocationList = list;
            break;
          case JourneyType.ROUNDTRIP:
            this.j2PickupLocationList = this.Destinations;
            list = this.j2PickupLocationList.filter(i => i.id != item.id);
            this.j2PickupLocationList = list;
            break;

          default:
            break;
        }
      }
    }
  }

  onBookingTrip(tripObj: any) {
    return this._http.post(this._baseUrl = 'https://parisdisneyshuttle.com/API/Postback.php?sID=' + '123456', tripObj);
  }

  // getLocations

  getFare(pick, drop, pass) {
    const data = {
      action: 'GetPrices',
      body: {
        i_from: pick,
        i_to: drop,
        i_passenger_count: pass,
        i_vehicle_type: 1
      }
    };


    return this._http.post(this._baseUrl + '123456', data);
  }
  getLocations() {
    const sID = '123456';
    return this._http.post(this._baseUrl + sID, {
      action: 'GetDestinations',
      body: {
        i_type: 3
      }
    });
  }
  getCosts() {
    const sID = '123456';
    return this._http.post(this._baseUrl + sID, {
      action: 'ListPrices',
      body: {
      }
    });
  }

  sendMsg(body) {
    const data = {
      action: 'SendMessage',
      body: body
    };

    return this._http.post(this._baseUrl + '123456', data);
  }
}

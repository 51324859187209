import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { appConfig } from './app.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'PARISNG';
  appConfig = appConfig;

  constructor(private router: Router,private route:ActivatedRoute ) {
    console.log(this.router.url);
    console.log(this.route);
  }


}

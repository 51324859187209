import { Pipe, PipeTransform } from '@angular/core';
import { PeakTimePipe } from './pipes/peak-time.pipe';

@Pipe({
  name: 'totalCost'
})
export class TotalCostPipe implements PipeTransform {
  filterPipe = new PeakTimePipe();
  //  transform(value: any, argTime?: any): any {
  //   const format = 'HH:mm'
  //   let peakCost = null;
  //   console.log('argTime ', argTime)
  //   const time = argTime ? moment(argTime, format) : moment(moment().format(format), format);
  //   console.log('Time ', moment(moment(argTime).format(format), format));
  //   const beforeTime = moment('20:30', format);
  //   const afterTime = moment('06:00', format);
  //   if (time.isSameOrBefore(beforeTime) && time.isSameOrAfter(afterTime)) {
  //     peakCost = value;
  //   } else {
  //     peakCost = 10;
  //   }
  //   return peakCost;
  // }
  transform(arg1:any,arg2:any,time1:any,time2:any){
   let val1 = this.filterPipe.transform(arg1,time1)
    let val2 = this.filterPipe.transform(arg2,time2)
    console.log(val1)
    console.log(val2)
    return Number(Number(val1)+Number(val2));
  }

}

export enum DestinationGroups{
    AIRPORTS = "Airports",
    OTHER = "Other",
    TRAIN_STATIONS = "Paris Train Stations"
}

export enum DestinationType{
    PICKUP = 1,
    DROP = 2
}

export enum JourneyType{
    ONEWAY = 1,
    ROUNDTRIP = 2
}
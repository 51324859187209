import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonfuncService {

  constructor() { }

  stringToDate(sDate: string) {
    try {
      // var st = date.toLocaleDateString('en-GB');
      var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
      var dt = new Date(sDate.replace(pattern,'$3-$2-$1'));
      return dt;
    } catch (error) {
      console.log(error + "Error converting date", sDate)
    }
  }

  stringToTime(sTime: string) {
    try {
      let st = new Date().toLocaleDateString('en-GB');
      let pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
      let dt = new Date(st.replace(pattern, '$3-$2-$1 ' + sTime));
      return dt;
    } catch (error) {
      console.log(error + "Error converting time", sTime);
    }
  }

  dateToString(date: Date){
    try {
      
    } catch (error) {
      console.log(error + "Error converting to Date", date);
    }
  }
  timeToString(time: Date){
    try {
      
    } catch (error) {
      console.log(error + "Error converting to time", time);
    }
  }

  public validateEmail(email: string){
    if((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))){
      return true;
    }else{
      return false;
    }
  }
}

import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { Destins, Destination, Cost } from '../../shared/models/models';
import { ReservationService } from '../bookings/reservation.service';
import { Router } from '@angular/router';
import { JourneyType } from '../../shared/enums/common-enums';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MainPageComponent implements OnInit {

  @ViewChild('pkup', { static: false }) pickupDrop: AngularMultiSelect;
  @ViewChild('drop', { static: false }) dropDrop: AngularMultiSelect;

  pickupLocation: string;
  pickupDropdownSettings = {};

  dropLocation: string;
  dropDropdownSettings = {};

  paxCountList = [];
  paxCount: number;
  paxCountSettings = {};

  bShowFare: boolean;
  fair: any = null;

  locationList: Destination[];
  costList: Cost;


  constructor(
    public reservationService: ReservationService,
    private router: Router,
  ) {
    this.bShowFare = false;
    this.pickupLocation = "";
    this.dropLocation = "";
    this.paxCount = 0;
  }

  ngOnInit() {
    this.reservationService.pickupLocationList = this.reservationService.pickupLocationList;
    this.reservationService.dropLocationList = this.reservationService.dropLocationList;
    this.paxCountList = this.reservationService.paxCountList;

    this.pickupDropdownSettings = this.reservationService.pickupDropdownSettings;
    this.dropDropdownSettings = this.reservationService.dropDropdownSettings;
    this.paxCountSettings = this.reservationService.paxCountSettings;
  }


  onPickupSelectMob(evnt: any) {
    this.onPickupSelect(this.getLocation(evnt.target.value));
  }

  onDropSelectMob(evnt: any) {
    console.log(evnt.target.value)
    this.onDropSelect(this.getLocation(evnt.target.value));
  }

  onPaxSelectMob(evnt: any){
    this.onPaxSelect({id: evnt.target.value, itemName:  evnt.target.value})
  }

  getLocation(id){
    for(let mkey in this.reservationService.pickupLocationList)
    if(this.reservationService.pickupLocationList[mkey].id == id) return this.reservationService.pickupLocationList[mkey];
  }

  getpaxlist(){
    let response = [];
    for(let i = 1; i < 31; i++) response.push(i);
    return response;
  }

  onPickupSelect(item: any) {
    console.log(item)
    this.reservationService.onPickupSelect(item, JourneyType.ONEWAY);
    this.pickupDrop.closeDropdown();
    
    if (this.reservationService.pickupLocation[0] && this.reservationService.dropLocation[0] && this.reservationService.paxCount[0]) 
    this.findFare();
  }
  onDropSelect(item: any) {
    console.log(item)
    this.reservationService.onDropSelect(item, JourneyType.ONEWAY);
    this.dropDrop.closeDropdown();
    if (this.reservationService.pickupLocation[0] && this.reservationService.dropLocation[0] && this.reservationService.paxCount[0]) 
    this.findFare();
  }
  onPaxSelect(item: any) {
    console.log(item)
    this.reservationService.onPaxSelect(item);
    if (this.reservationService.pickupLocation[0] && this.reservationService.dropLocation[0] && this.reservationService.paxCount[0]) 
    this.findFare();
  }

  findFare() {
    if (this.reservationService.pickupLocation[0] && this.reservationService.dropLocation[0] && this.reservationService.paxCount[0]) {
      // this.pickupLocation = this.reservationService.pickupLocation[0].itemName;
      // this.dropLocation = this.reservationService.dropLocation[0].itemName;
      // this.paxCount = this.reservationService.paxCount[0].itemName;
      this.bShowFare = !this.bShowFare;
      const pick = (this.reservationService.pickupLocation[0].id);
      const drop = (this.reservationService.dropLocation[0].id);
      const passengersCount = (this.reservationService.paxCount[0].itemName);
      console.log('pick ', this.reservationService.pickupLocation)
      console.log('drop ', this.reservationService.dropLocation)
      console.log('passanger ', this.reservationService.paxCount)
      this.getCostFromServices(pick, drop, passengersCount);

    }

  }
  getCostFromServices(p: number, d: number, count: number) {
    this.fair = null;
    this.reservationService.getFare(p, d, count)
      .subscribe(response => {
        const res: any = response;
        if (res.success) {
          this.fair = res.body.cost;
        }
      })
  }

  bookNow() {
    document.getElementById('header').scrollIntoView();
    this.router.navigate(["/reservations"]);
  }
  onGetLocatioList() {
    this.reservationService.getLocations()
      .subscribe(response => {
        const res: any = response;
        // this.locationList = res.body.destinations
        this.locationList = [];
        // tslint:disable-next-line: forin
        for (const key in res.body.destinations) {
          this.locationList.push(res.body.destinations[key]);
        }
        console.log(this.locationList)
        this.onGetCostList();
      })
  }
  onGetCostList() {
    this.reservationService.getCosts()
      .subscribe(response => {
        const res: any = response;
        this.costList = res.body;
        console.log(this.costList)
        console.log(this.costList.locations[0])
      })
  }
}
